<template >
  <div class="containter">
    <div class="height10"></div>
    <div class="radioButtonLine">
      <div class="radioButtonItem" @click="changeList(1)">
        <div class="radioButtonFont" :style="selectRow===1?'color:rgb(252,0,36)':''">待领取</div>
        <div
          class="radioButtonBottomLine"
          :style="selectRow===1?'background-color:rgb(252,0,36)':''"
        ></div>
      </div>
      <div class="radioButtonItem" @click="changeList(2)">
        <div class="radioButtonFont" :style="selectRow===2?'color:rgb(252,0,36)':''">进行中</div>
        <div
          class="radioButtonBottomLine"
          :style="selectRow===2?'background-color:rgb(252,0,36)':''"
        ></div>
      </div>
      <div class="radioButtonItem" @click="changeList(3)">
        <div class="radioButtonFont" :style="selectRow===3?'color:rgb(252,0,36)':''">已结束</div>
        <div
          class="radioButtonBottomLine"
          :style="selectRow===3?'background-color:rgb(252,0,36)':''"
        ></div>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        :offset="70"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div class="listCell" v-for="item in list" :key="item.id">
          <div class="listItem">
            <!-- <img v-if="selectRow===2" :src="endActivity" class="listItemSignInImg" /> -->
            <div class="listItemTitleLine">
              <div class="avtorArea">
                <!-- <img :src="item.executorAvatar" style="width:100%;height:100%;" /> -->
                <van-image fit="cover" :src="item.executorAvatar" style="width:100%;height:100%;">
                  <template v-slot:error>
                    <img :src="errorPicAvatar" style="width:100%;height:100%;" />
                  </template>
                </van-image>
              </div>
              <div class="listItemTitleLineContent">
                <div class="listItemTitleLineContent1">{{ item.branchName}}</div>
                <div class="listItemTitleLineContent2">{{ item.startTime}}</div>
              </div>
            </div>
            <div class="baseLine"></div>
            <div class="mainTitleLine">
              <span class="marginLeft10">{{item.sname}}</span>
            </div>
            <div class="mainImgArea" @click="gotoDetail(item)">
              <!-- <img :src="item.titleImg" class="mainImg" /> -->
              <van-image fit="cover" :src="item.titleImg" class="mainImg">
                <template v-slot:error>
                  <img :src="errorImg" style="width:100%;height:100%;" />
                </template>
              </van-image>
            </div>
            <div class="mainBottomLine">
              <div class="leftMainArea">
                <span class="peopleFont" v-if="item.approveStatus===2">审核人：{{item.auditPersonName}}</span>
                <span class="peopleFont" v-else>参与人：{{item.executorName}}</span>
              </div>
              <div class="rightMainArea">
                <!-- 立刻领取 -->
                <div
                  v-if="item.status===0"
                  class="baomingButton backRed"
                  @click="getTaskPost(item)"
                >立即领取</div>
                <!-- 进行中 -->
                <div v-if="item.status===1" class="baomingButton backWhite">进行中</div>
                <!-- 已终止 -->
                <div v-if="item.status===4" class="baomingButton backGrey">已终止</div>
                <!-- 已完成 -->
                <div v-if="item.status===2" class="baomingButton backGrey">已完成</div>
                <div v-if="item.status===3" class="baomingButtonFinish backGrey">
                  已完成+
                  <span style="color:#FF192F">{{item.score&&item.score!==-1?item.score:0}}分</span>
                </div>
                <div
                  v-if="item.expired&&item.status!==1&&item.status!==0"
                  class="baomingButton backGrey"
                >已过期</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Notify } from 'vant';
import {
  getTaskListByCatch,
  getTaskListByEnd,
  getTaskListByRunning,
  getTaskPost
} from '../../api/task';
import * as dd from 'dingtalk-jsapi';

export default {
  name: 'ActivityList',

  data() {
    return {
      // 任务的四种状态 1-待领取 2-进行中 3-已完成 4-已终止
      // 审核的两种状态 1-待审核 2-已审核
      searchIconVisible: true,
      redLove: require('../activity/imgs/redHeart.png'),
      greyLove: require('../activity/imgs/greyHeart.png'),
      eyeIcon: require('../activity/imgs/eyeIcon.png'),
      signInImg: require('../activity/imgs/signIn.png'),
      endActivity: require('../activity/imgs/endingActivity.png'),
      errorImg: require('../../assets/img/list.png'),
      errorPicAvatar: require('../../assets/img/list-compre.png'),
      searchInput: '',
      placehold: '活动名称',
      selectRow: 1,
      param: {
        page: 0,
        size: 5,
        total: 20
      },
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      fakeList: [],
      list: []
    };
  },
  watch: {},
  created() {
    dd.biz.navigation.setTitle({
      title: '任务', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
  },
  // 如果从首页返回就退出
  beforeRouteEnter(to, from, next) {
    let selectRow = 1;
    if (from.path === '/taskDetail') {
      selectRow = Number(localStorage.getItem('taskListSelectRow'));
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      console.log(vm);
      vm.selectRow = selectRow; //当前组件的实例
      vm.getRecords();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    const selectRow = this.selectRow;
    localStorage.setItem('taskListSelectRow', selectRow);
    next();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      const param = this.param;
      param.page += 1;
      const searchParam = { ...param };
      const selectRow = this.selectRow;
      // 调接口
      if (selectRow === 1) {
        getTaskListByCatch({
          current: searchParam.page,
          size: 5
        })
          .then(res => {
            if (res.success) {
              const activityList = res.data.records;
              let list = this_.list;
              list = list.concat(activityList);
              this_.list = JSON.parse(JSON.stringify(list));
              console.log(this_.list);
              param.total = res.data.total;
              this_.param = param;
              // 加载状态结束
              this_.loading = false;
              this_.refreshing = false;
              this_.error = false;
              // 数据全部加载完成
              if (this_.list.length >= param.total) {
                this_.finished = true;
              }
            } else {
              // 加载状态结束
              this_.error = true;
              console.log(res.msg);
            }
          })
          .catch(err => {
            // 加载状态结束
            this_.error = true;
            console.log(err);
          });
      }
      if (selectRow === 2) {
        getTaskListByRunning({
          current: searchParam.page,
          size: 5
        })
          .then(res => {
            if (res.success) {
              const activityList = res.data.records;
              let list = this_.list;
              list = list.concat(activityList);
              this_.list = JSON.parse(JSON.stringify(list));
              console.log(this_.list);
              param.total = res.data.total;
              this_.param = param;
              // 加载状态结束
              this_.loading = false;
              this_.refreshing = false;
              // 数据全部加载完成
              if (this_.list.length >= param.total) {
                this_.finished = true;
              }
            } else {
              this_.error = true;
              console.log(res.msg);
            }
          })
          .catch(err => {
            this_.error = true;
            console.log(err);
          });
      }
      if (selectRow === 3) {
        getTaskListByEnd({
          current: searchParam.page,
          size: 5
        })
          .then(res => {
            if (res.success) {
              const activityList = res.data.records;
              let list = this_.list;
              list = list.concat(activityList);
              this_.list = JSON.parse(JSON.stringify(list));
              console.log(this_.list);
              param.total = res.data.total;
              this_.param = param;
              // 加载状态结束
              this_.loading = false;
              this_.refreshing = false;
              // 数据全部加载完成
              if (this_.list.length >= param.total) {
                this_.finished = true;
              }
            } else {
              this_.error = true;
              console.log(res.msg);
            }
          })
          .catch(err => {
            this_.error = true;
            console.log(err);
          });
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.list = [];
      this.param.page = 0;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getRecords();
    },
    //加载更多数据
    onLoad() {
      const timer = setTimeout(() => {
        this.getRecords();
        this.loading = false;
        clearTimeout(timer);
      }, 500);
    },
    // 查询的改变
    changeList(num) {
      this.selectRow = num;
      this.param.page = 0;
      this.finished = false;
      this.loading = true;
      this.list = [];
      this.onLoad();
    },
    getSearch() {
      this.param.page = 0;
      this.finished = false;
      this.list = [];
      this.onLoad();
    },
    // 领取任务
    getTaskPost(item) {
      const this_ = this;
      getTaskPost({
        missionId: item.missionId
      })
        .then(res => {
          if (res.success) {
            this_.getSearch();
            Notify({ type: 'success', message: '领取成功' });
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    gotoDetail(row) {
      this.$router.push({
        name: 'taskDetail',
        query: { detailId: row.missionId }
      });
    },
    getInputChange() {
      this.searchIconVisible = false;
    },
    blurChange() {
      this.searchIconVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-icon {
  display: flex;
  align-items: center;
}
/deep/ .van-cell {
  padding: 0px;
}
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headSearchLine {
  width: 100%;
  height: 40 * $px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.searchInput {
  width: 355 * $px;
  height: 29 * $px;
  line-height: 29 * $px;
  border-radius: 14.5 * $px;
  background-color: #f5f5f5;
  padding: 0px;
}
.searchIcon {
  width: 12 * $px;
  height: 12 * $px;
  position: absolute;
  top: 13.5 * $px;
  left: 146 * $px;
  z-index: 1;
}
.radioButtonLine {
  height: 34 * $px;
  width: 100%;
  background-color: #ffffff;
}
.radioButtonItem {
  width: 33.33%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.radioButtonFont {
  font-size: 14 * $px;
  color: #555555;
  margin-bottom: 7 * $px;
}
.radioButtonBottomLine {
  width: 30 * $px;
  height: 2 * $px;
  border-radius: 0.5 * $px;
  background-color: #ffffff;
}
.listItem {
  width: 100%;
  height: 100%;
  position: relative;
}
.listCell {
  margin: 10 * $px 10 * $px 0 10 * $px;
  width: 355 * $px;
  height: 293.5 * $px;
  background-color: #ffffff;
}
.listItemTitleLine {
  width: 100%;
  height: 80 * $px;
}
.avtorArea {
  width: 40 * $px;
  height: 40 * $px;
  border-radius: 20 * $px;
  overflow: hidden;
  background-color: grey;
  margin: 10 * $px;
  float: left;
}
.listItemTitleLineContent {
  width: 280 * $px;
  height: 100%;
  float: left;
}
.listItemTitleLineContent1 {
  font-size: 14 * $px;
  color: #555555;
  margin: 12 * $px 45 * $px 0 * $px 10 * $px;
}
.listItemTitleLineContent2 {
  font-size: 11 * $px;
  color: #999999;
  margin-left: 10 * $px;
}
.baseLine {
  width: 335 * $px;
  height: 0.5 * $px;
  margin-left: 10 * $px;
  background-color: #eeeeee;
}
.mainTitleLine {
  width: 100%;
  height: 39 * $px;
  line-height: 39 * $px;
  color: #222222;
  font-size: 16 * $px;
}
.marginLeft10 {
  margin-left: 10 * $px;
}
.mainImgArea {
  width: 100%;
  height: 130 * $px;
  display: flex;
  justify-content: center;
}
.mainImg {
  width: 335 * $px;
  height: 100%;
}
.mainBottomLine {
  width: 100%;
  height: 44 * $px;
}
.leftMainArea {
  width: 30%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rightMainArea {
  width: 70%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftMainAreaImg {
  width: 16 * $px;
  height: 14.5 * $px;
  margin-left: 12 * $px;
}
.leftMainAreaFont {
  width: 50 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainAreaFont2 {
  width: 62 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainEyeImg {
  width: 16 * $px;
  height: 12 * $px;
}
.leftMainAreaFontNum {
  font-size: 12 * $px;
  color: #555555;
}
.leftMainAreaFontNumNow {
  color: #2797ff;
  font-size: 16 * $px;
}
.baomingButton {
  width: 70 * $px;
  height: 24 * $px;
  border-radius: 12 * $px;
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12 * $px;
  margin-right: 10 * $px;
  margin-left: 10 * $px;
}
.baomingButtonFinish {
  width: 90 * $px;
  height: 24 * $px;
  border-radius: 12 * $px;
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12 * $px;
  margin-right: 10 * $px;
  margin-left: 10 * $px;
}
.backRed {
  background-color: #ff192f;
  color: #ffffff;
}
.backWhite {
  background-color: #ffffff;
  color: #ff192f;
}
.backGrey {
  background-color: #ffffff;
  color: #999999;
  border: #999999 1 * $px solid;
}
.listItemSignInImg {
  width: 70 * $px;
  height: 70 * $px;
  position: absolute;
  top: 15 * $px;
  right: 15 * $px;
}
.height10 {
  width: 100%;
  height: 10 * $px;
  background-color: #ffffff;
}
.peopleFont {
  color: #555555;
  font-size: 12 * $px;
  margin-left: 13 * $px;
}
</style>



